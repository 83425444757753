
.logo {
    float: left;
    height: 31px;
    margin: 0px 24px 16px 0;
    font-size: 1.3em;
    color: white;
  }

.navbarStyle {
    padding: .5rem 1rem !important
}

.navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
}

.iconText {
    margin: 0 .5rem
}

.tableColumn {
    word-break: break-all
}

.site-layout-content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.ant-layout-header {
    padding-left: 24px !important;
    padding-right: 14px !important;
}

.ant-layout-footer {
    border-top: 1px solid rgba(0,0,0,.1);
    box-shadow: 0px 24px 12px rgba(0,0,0,.025) inset;
}

.header-fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index:100;
}
.table {
    max-height: 37rem;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.auth-button-container {
    float: right;
    height: 64px;
    vertical-align: middle;
}

.gray {
    color: gray;
}

.font-size-1-1em {
    font-size: 1.1em;
}

.content-card p {
    margin-bottom: 0;
}
.ql-container {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-color: rgb(217,217,217) !important;
}
.ql-editor {
    padding: 6px 11px 8px 11px;
}
.ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 4px 4px !important;
    white-space: nowrap;
    overflow-x: auto;
    border-color: rgb(217,217,217) !important;
}

.ant-form-item-row[type="hidden"] .ant-form-item-control-input {
    min-height: 0 !important;
}
.ql-snow.ql-toolbar button svg, 
.ql-snow .ql-toolbar button svg {
    height: 15px;
}
.ant-table table tbody tr td {
    background-color: white;
}